<template>
  <div class="similation-facture-frais">
    <div class="entete">
      <div class="box-all-filter">
        <div class="box-label-champ mr-2">
          <div class="label-box-style label-box-style-w-1 p-4-5">
            <span class="title-tabel">Année</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select b-form-select-new-style-w-1"
            v-model="filteryear"
            :options="ListYear"
            @change="handleChangeYear"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style  label-box-style-w-1">
            <span class="title-tabel">Mois</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select  "
            v-model="filterMonth"
            :options="[
              { value: null, text: 'Tous' },
              ...computedGetMonthForYearTh
            ]"
            @change="handleChangeMonth"
            text-field="text"
            value-field="value"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style w-46-px">
            <span class="title-tabel">Semaine</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select w-63-px "
            v-model="filterWeek"
            :options="[
              { value: null, text: 'Tous' },
              ...getcomputedListWeek(filterMonth)
            ]"
            text-field="text"
            value-field="value"
            @change="handleChangeWeek"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style  w-93-px">
            <span class="title-tabel">Filiale Installateur</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select  w-63-px"
            v-model="filterFiliale"
            :options="[
              { value: null, text: 'Tous' },
              ...getcomputedFiliale(filterMonth, filterWeek)
            ]"
            text-field="text"
            value-field="value"
            @change="handleChangeFiliale"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style  w-46-px">
            <span class="title-tabel">Dépôt</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select w-160-px "
            v-model="filterDepot"
            :options="[
              { value: null, text: 'Tous' },
              ...getcomputedDepots(filterMonth, filterWeek, filterFiliale)
            ]"
            text-field="text"
            value-field="value"
            @change="handleChangeDepot"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style  w-93-px">
            <span class="title-tabel">Confirmation</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select w-63-px "
            v-model="filterConfirmation"
            :options="[
              { value: null, text: 'Tous' },
              { value: 'oui', text: 'Oui' },
              { value: 'non', text: 'Non' }
            ]"
            text-field="text"
            value-field="value"
            @change="handleChangeDepot"
          ></b-form-select>
        </div>
        <div class="error-message ml-2 mr-2">
          <div v-if="getErrorSimulationFactureFrais" class="error">
            <ul
              v-if="Array.isArray(getErrorSimulationFactureFrais)"
              class="mb-0"
            >
              <li
                v-for="(e, index) in getErrorSimulationFactureFrais"
                :key="index"
              >
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ getErrorSimulationFactureFrais }}</div>
          </div>
        </div>
        <div
          v-if="getLoadingSimulationFactureFrais"
          class="chargement chargement-loading-icon"
        >
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="box-end-position">
          <search :value="searchValue" @changeSearchValue="changeSearchValue" />
          <b-button
            size="sm"
            class="button-export-style  ml-2 "
            :class="{
              'show-montant-button': showCoef == true,
              'show-coef-button': showCoef == false
            }"
            :title="
              !showCoef ? 'Afficher les coefficients' : 'Afficher les montants'
            "
            @click.prevent="showCoef = !showCoef"
          >
            <font-awesome-icon icon="euro-sign"
          /></b-button>
          <confirmation-dossier-frais
            v-if="filterConfirmation != null"
            :dataToUse="computedSimulationFactureFrais"
            @reloadData="handleFilter"
            :filterConfirmation="filterConfirmation"
          />
        </div>
      </div>
    </div>
    <div class="body-box-rapport ">
      <div class="table-rapport-style w-100-p">
        <b-table
          show-empty
          id="my-table"
          class="custom-table-style customTableSimulationFactureFrais table-header"
          :items="computedSimulationFactureFrais"
          :fields="computedFields"
          bordered
          sticky-header
          hover
          responsive
          head-variant="light"
          :tbody-tr-class="rowClass"
          empty-text="Il n'y a aucun enregistrement à afficher"
        >
          <!-- <template slot="thead-top" slot-scope="">
            <tr>
              <th :colspan="6"></th>
              <th :colspan="1"></th>
              <th :colspan="1"></th></tr
          >
          </template> -->
          <template v-slot:head()="data">
            <div class="text-uppercase">
              <span v-if="data.field.key != 'check_all'">
                {{ data.field.label
                }}<span v-if="data.field.editable">
                  <column-header-with-tool-tip
                    :rowDetails="false"
                    :label="data.field.label"
                    :column="
                      data.field.key == 'bonification_cactus'
                        ? 'commercial'
                        : data.field.key
                    "
                    :dataToUse="computedSimulationFactureFrais"
                    :updateFunction="updateTauxFraisInInterface"
                    @reloadData="handleFilter"/></span
              ></span>
              <div v-else>
                <b-form-checkbox
                  v-model="checkAll"
                  @change="checkAllFunction"
                  class="check-th"
                  :value="true"
                  :unchecked-value="false"
                >
                </b-form-checkbox>
              </div>
            </div>
          </template>
          <template v-slot:cell(check_all)="data">
            <b-form-checkbox
              v-model="data.item.check"
              @click="checkAllVerif(data.item)"
              class="check-th"
              :value="true"
              :unchecked-value="false"
            >
            </b-form-checkbox>
          </template>
          <template v-slot:cell(numero_dossier)="data">
            {{ data.item.numero_dossier }}
          </template>
          <template v-slot:cell(nom_prenom)="data">
            {{ data.item.nom_prenom }}
          </template>
          <template v-slot:cell(montant_final)="data">
            {{ data.item.montant_final }} €
          </template>
          <template v-slot:cell(regie)="data">
            {{ data.item.regie }}
          </template>
          <template v-slot:cell(filiale)="data">
            {{ data.item.filiale }}
          </template>
          <template v-slot:cell(operationnel)="data">
            <column-frais-simulation
              :showCoef="showCoef"
              :item="data.item"
              column="operationnel"
              :updateFunction="updateCoefFraisSimulation"
            />
          </template>
          <template v-slot:cell(coef_cactus_globale)="data">
            {{ data.item.cactus }}
          </template>
          <template v-slot:cell(bonification_cactus)="data">
            <column-frais-simulation
              :showCoef="showCoef"
              :item="data.item"
              column="commercial"
              :updateFunction="updateCoefFraisSimulation"
              :coef="true"
            />
          </template>
          <template v-slot:cell(commercial)="data">
            <column-frais-simulation
              :showCoef="showCoef"
              :item="data.item"
              column="commercial"
              :updateFunction="updateCoefFraisSimulation"
              :editable="false"
            />
          </template>
          <template v-slot:cell(externe_value)="data">
            <column-frais-simulation
              :showCoef="showCoef"
              :item="data.item"
              column="externe_value"
              :updateFunction="updateCoefFraisSimulation"
            />
          </template>
          <template v-slot:cell(alteo_value)="data">
            <column-frais-simulation
              :showCoef="showCoef"
              :item="data.item"
              column="alteo_value"
              :updateFunction="updateCoefFraisSimulation"
            />
          </template>
          <template v-slot:cell(commercial_value)="data">
            <column-frais-simulation
              :showCoef="showCoef"
              :item="data.item"
              column="commercial_value"
              :updateFunction="updateCoefFraisSimulation"
            />
          </template>
          <template v-slot:cell(interne_value)="data">
            <column-frais-simulation
              :showCoef="showCoef"
              :item="data.item"
              column="interne_value"
              :updateFunction="updateCoefFraisSimulation"
            />
          </template>
          <template v-slot:cell(ihs)="data">
            <column-frais-simulation
              :showCoef="showCoef"
              :item="data.item"
              column="ihs"
              :updateFunction="updateCoefFraisSimulation"
              :editable="false"
            />
          </template>
          <template v-slot:cell(marketing)="data">
            <column-frais-simulation
              :showCoef="showCoef"
              :item="data.item"
              column="marketing"
              :updateFunction="updateCoefFraisSimulation"
            />
          </template>
          <template v-slot:cell(technique)="data">
            <column-frais-simulation
              :showCoef="showCoef"
              :item="data.item"
              column="technique"
              :updateFunction="updateCoefFraisSimulation"
            />
          </template>

          <template v-slot:cell(rge)="data">
            <column-frais-simulation
              :showCoef="showCoef"
              :item="data.item"
              column="rge"
              :updateFunction="updateCoefFraisSimulation"
            />
          </template>

          <template v-slot:cell(CDG)="data">
            <column-frais-simulation
              :showCoef="showCoef"
              :item="data.item"
              column="CDG"
              :updateFunction="updateCoefFraisSimulation"
            />
          </template>

          <template v-slot:cell(administratif_sav)="data">
            <column-frais-simulation
              :showCoef="showCoef"
              :item="data.item"
              column="administratif_sav"
              :updateFunction="updateCoefFraisSimulation"
            />
          </template>

          <template v-slot:cell(dsi)="data">
            <column-frais-simulation
              :showCoef="showCoef"
              :item="data.item"
              column="dsi"
              :updateFunction="updateCoefFraisSimulation"
            />
          </template>
          <template v-slot:cell(upcube)="data">
            <column-frais-simulation
              :showCoef="showCoef"
              :item="data.item"
              column="upcube"
              :updateFunction="updateCoefFraisSimulation"
            />
          </template>
          <template v-slot:cell(juridique)="data">
            <column-frais-simulation
              :showCoef="showCoef"
              :item="data.item"
              column="juridique"
              :updateFunction="updateCoefFraisSimulation"
            />
          </template>

          <template v-slot:cell(alger)="data">
            <column-frais-simulation
              :showCoef="showCoef"
              :item="data.item"
              column="alger"
              :updateFunction="updateCoefFraisSimulation"
            />
          </template>
          <template v-slot:cell(alteo_control_gestion)="data">
            <column-frais-simulation
              :showCoef="showCoef"
              :item="data.item"
              column="alteo_control_gestion"
              :updateFunction="updateCoefFraisSimulation"
            />
          </template>

          <template v-slot:cell(bureau_detude)="data">
            <column-frais-simulation
              :showCoef="showCoef"
              :item="data.item"
              column="bureau_detude"
              :updateFunction="updateCoefFraisSimulation"
            />
          </template>
          <template v-slot:cell(binifis_kynan)="data">
            <column-frais-simulation
              :showCoef="showCoef"
              :item="data.item"
              column="binifis_kynan"
              :updateFunction="updateCoefFraisSimulation"
            />
          </template>
          <template v-slot:cell(binifis_alteo)="data">
            <column-frais-simulation
              :showCoef="showCoef"
              :item="data.item"
              column="binifis_alteo"
              :updateFunction="updateCoefFraisSimulation"
            />
          </template>
          <template v-slot:cell(mairie)="data">
            <column-frais-simulation
              :showCoef="showCoef"
              :item="data.item"
              column="mairie"
              :updateFunction="updateCoefFraisSimulation"
            />
          </template>

          <template v-slot:cell(maintenance_pac)="data">
            <column-frais-simulation
              :showCoef="showCoef"
              :item="data.item"
              column="maintenance_pac"
              :updateFunction="updateCoefFraisSimulation"
            />
          </template>
          <template v-slot:cell(validation)="data">
            <div>
              <confirmation-unique
                :data="data"
                :id="data.item.id"
                :index="computedSimulationFactureFrais.length"
              />
              <!-- <b-button
                v-if="data.item.confirme_frais == false"
                size="sm"
                class="iconValide activeConfirm"
                title="salaire valide"
                @click.prevent.stop="handelValidationSalary(data.item)"
              >
                <font-awesome-icon icon="check" />
              </b-button>
              <b-button
                v-if="data.item.confirme_frais == true"
                size="sm"
                class="iconInvalide confirmInvalid"
                title="salaire invalide"
                @click.prevent.stop="handelValidationSalary(data.item)"
              >
                <font-awesome-icon icon="times" />
              </b-button> -->
            </div>
          </template>
        </b-table>
        <div class=" footer-style">
          <b-pagination
            v-model="page"
            :total-rows="getSimulationTotalRow"
            :per-page="perPage"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>
          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="perPage"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import columnFraisSimulation from './component/columnFraisSimulation.vue';
import confirmationUnique from './component/confirmationUnique.vue';
import moment from 'moment';
export default {
  name: 'Simulation-Facture-Frais',
  data() {
    return {
      page: 1,
      perPage: 100,
      perPageList: [
        { value: 50, text: 50 },
        { value: 100, text: 100 },
        { value: 200, text: 200 }
      ],
      showCoef: false,
      checkAll: false,
      filterWeek: null,
      filteryear: null,
      ListYear: [],
      filterMonth: null,
      filterFiliale: null,
      filterDepot: null,
      filterConfirmation: 'non',
      fields: [
        {
          key: 'check_all',
          label: '',
          thClass: 'th-check-row-th',
          tdClass: 'col-check-row-th'
        },
        {
          key: 'numero_dossier',
          label: 'Numéro de dossier',
          thClass: 'colhead-num-dossier-th',
          tdClass: 'col-num-dossier-th',
          editable: false
        },

        { key: 'nom_prenom', label: 'Nom et Prénom', editable: false },
        {
          key: 'filiale',
          label: 'Filiale Installateur',
          editable: false
        },
        {
          key: 'regie',
          label: 'Régie',
          editable: false
        },
        { key: 'montant_final', label: 'Montant min', editable: false },

        {
          key: 'operationnel',
          label: 'Gestion Opérationnel (NOVAE)',
          thClass: 'bg-color-th-operationnel',
          editable: true
        },

        {
          key: 'marketing',
          label: 'Gestion Marketing (PINKMAN)',
          thClass: 'bg-color-th-markiting',
          for: 'AXELIUS',
          editable: true
        },
        {
          key: 'technique',
          label: 'Gestion Technique (KYNAN)',
          thClass: 'bg-color-th-technique',
          editable: true
        },
        {
          key: 'rge',
          label: 'Gestion RGE (CERTILIA)',
          thClass: 'bg-color-th-rge',
          editable: true
        },
        {
          key: 'CDG',
          label: 'CDG (GE CDG)',
          thClass: 'bg-color-th-cdg',
          editable: true
        },
        {
          key: 'administratif_sav',
          label: 'Gestion Administratif - SAV  (SAMI-B)',
          thClass: 'bg-color-th-administratif-sav',
          editable: true
        },

        {
          key: 'juridique',
          label: 'Juridique (CORPEDYS)',
          thClass: 'bg-color-th-juridique',
          editable: true
        },
        {
          key: 'alger',
          label: 'Alger - Gestion (ALTEO France)',
          thClass: 'bg-color-th-alger',
          editable: true
        },
        {
          key: 'alteo_control_gestion',
          label: 'Alteo contrôle qualité',
          thClass: 'bg-color-th-alger',
          editable: true
        },
        {
          key: 'mairie',
          label: 'Mairie (NOVAE MAIRIE)',
          thClass: 'bg-color-th-marie',
          editable: true
        },
        {
          key: 'maintenance_pac',
          label: 'Maintenace PAC (GSE)',
          thClass: 'bg-color-th-maintenance-pac',
          editable: true
        },
        {
          key: 'dsi',
          label: 'DSI  (WEB ABONDANCE)',
          thClass: 'bg-color-th-dsi',
          editable: true
        },
        {
          key: 'upcube',
          label: 'UPCUBE TECHNOLOGIES',
          thClass: 'bg-color-th-upcube',
          editable: true
        },
        //
        {
          key: 'coef_cactus_globale',
          label: 'Coef globale',
          thClass: 'bg-color-th-commercial',
          editable: false
        },
        {
          key: 'bonification_cactus',
          label: 'Bonification cactus',
          thClass: 'bg-color-th-commercial',
          editable: true
        },
        {
          key: 'commercial',
          label: 'Gestion Commercial (CACTUS)',
          thClass: 'bg-color-th-commercial',
          editable: false
        },

        {
          key: 'externe_value',
          label: 'LAV Régie Commerciale EXTERNE',
          thClass: 'bg-color-th-comptabilite',
          editable: true
        },
        {
          key: 'alteo_value',
          label: 'ALTEO FRANCE Supervision',
          thClass: 'bg-color-th-bureau-etude',
          editable: true
        },
        {
          key: 'commercial_value',
          label: 'ALTEO FRANCE Support Commercial ',
          thClass: 'bg-color-th-marie',
          editable: true
        },
        {
          key: 'interne_value',
          label: 'ALTEO FRANCE Régie Commerciale INTERNE',
          thClass: 'bg-color-th-maintenance-pac',
          editable: true
        },
        {
          key: 'ihs',
          label: 'INTERNE IHS',
          thClass: 'bg-color-th-ihs ',
          editable: false
        },
        //
        {
          key: 'bureau_detude',
          label: "Bureau d'étude",
          thClass: 'bg-color-th-bureau-etude',
          editable: true
        },
        {
          key: 'binifis_kynan',
          label: 'Montant bonification KYNAN',
          thClass: 'bg-color-th-technique',
          editable: true
        },
        {
          key: 'binifis_alteo',
          label: 'Montant bonification ALTEO',
          thClass: 'bg-color-th-alsger',
          editable: true
        },
        {
          key: 'validation',
          label: '',
          thClass: 'colhead-num-dossier-th-simulation  ',
          tdClass: ' col-num-dossier-th-simulation',
          editable: false
        }
      ],
      searchValue: null
    };
  },
  computed: {
    ...mapGetters([
      'getMonthForYearTh',
      'getAllDepotForYearTh',
      'getLoadingSimulationFactureFrais',
      'getSimulationFactureFraisData',
      'cantValid',
      'cantUpdate',
      'getErrorSimulationFactureFrais',
      'getcomputedListWeek',
      'getcomputedFiliale',
      'getcomputedDepots',
      'getSimulationTotalRow'
    ]),
    computedGetMonthForYearTh() {
      const tab = this.getMonthForYearTh.map(item => ({
        value: item.month_name,
        text: item.month_name
      }));
      return tab;
    },
    computedDepot() {
      let tab = [];
      if (this.filterMonth == null) {
        tab = this.getAllDepotForYearTh.map(item => ({
          value: item.depot,
          text: item.depot
        }));
        return tab;
      } else {
        let table = this.getMonthForYearTh.filter(
          item => item.month_name == this.filterMonth
        );
        if (table.length) {
          tab = table[0].depots.map(item => ({
            value: item.depot,
            text: item.depot
          }));
          return tab;
        } else {
          return [];
        }
      }
    },
    computedSimulationFactureFrais() {
      if (this.filterConfirmation != null) {
        let confirm = this.filterConfirmation == 'oui' ? true : false;
        return this.getSimulationFactureFraisData.filter(
          item => item.confirme_frais == confirm
        );
      }
      return this.getSimulationFactureFraisData;
    },
    computedFields() {
      return this.fields;
    }
  },
  methods: {
    ...mapActions([
      'fetchMonthForYearTH',
      'getAllSimulationFactureFrais',
      'updateCoefFraisSimulation',
      'confirmationDossierSimulation',
      'updateTauxFraisInInterface'
    ]),
    pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
      this.checkAll = false;
    },
    changePerPage() {
      this.page = 1;
      this.handleFilter();
      this.checkAll = false;
    },
    // handelValidationSalary(data) {
    //   this.confirmationDossierSimulation(data);
    // },
    setLocalStorageSimulationFraisFacture() {
      localStorage.setItem(
        'simulation-frais-facture',
        JSON.stringify({
          year: this.filteryear,
          month: this.filterMonth,
          week: this.filterWeek,
          filiale: this.filterFiliale,
          depot: this.filterDepot,
          confirmation: this.filterStatut,
          search: this.searchValue,
          confirmation: this.filterConfirmation
        })
      );
    },
    handleChangeYear() {
      this.filterMonth = null;
      this.filterDepot = null;
      this.filterWeek = null;
      this.filterFiliale = null;
      this.fetchMonthForYearTH(this.filteryear);
      this.handleFilter();
    },
    handleChangeMonth() {
      this.filterDepot = null;
      this.filterWeek = null;
      this.filterFiliale = null;
      this.handleFilter();
    },
    handleChangeWeek() {
      this.filterDepot = null;
      this.filterFiliale = null;
      this.handleFilter();
    },
    handleChangeFiliale() {
      this.filterDepot = null;
      this.handleFilter();
    },
    handleChangeDepot() {
      this.handleFilter();
    },
    changeSearchValue(value) {
      this.searchValue = value;
      this.handleFilter();
    },
    handleFilter() {
      this.setLocalStorageSimulationFraisFacture();
      this.getAllSimulationFactureFrais({
        year: this.filteryear,
        page: this.page,
        perPage: this.perPage,
        month: this.filterMonth,
        depot: this.filterDepot,
        search: this.searchValue,
        filiale: this.filterFiliale,
        week: this.filterWeek,
        confirmation: this.filterConfirmation
      });
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      if (item.confirme_frais == true) return 'ligneAlert';
      if (item.confirme_frais == false) return 'ligneAnomalie';
      return 'ligneEnCourDeValidation';
    },
    checkAllFunction() {
      this.computedSimulationFactureFrais.map(item => {
        item.check = this.checkAll;
      });
    },
    checkAllVerif(item) {
      if (item.check == false && this.checkAll == true) {
        this.checkAll = false;
      }
    }
  },
  components: {
    search: () => import('@/views/component/SearchInputComponent.vue'),
    columnFraisSimulation,
    confirmationDossierFrais: () =>
      import('./component/confirmationFraisDossier.vue'),
    columnHeaderWithToolTip: () =>
      import('../component/headerTable/columnHeaderWithToolTip.vue'),
    confirmationUnique
  },
  async mounted() {
    var courantYear = moment().year();
    this.filteryear = courantYear;

    if (localStorage.getItem('simulation-frais-facture')) {
      this.filteryear = JSON.parse(
        localStorage.getItem('simulation-frais-facture')
      ).year;
    }
    await this.fetchMonthForYearTH(this.filteryear);
    var courantMonthId = moment().month();
    var courantMonth = this.getMonthForYearTh[courantMonthId]
      ? this.getMonthForYearTh[courantMonthId].month_name
      : this.filterMonth;

    if (localStorage.getItem('simulation-frais-facture')) {
      this.filterMonth = JSON.parse(
        localStorage.getItem('simulation-frais-facture')
      ).month;
      this.filterDepot = JSON.parse(
        localStorage.getItem('simulation-frais-facture')
      ).depot;
      this.filterWeek = JSON.parse(
        localStorage.getItem('simulation-frais-facture')
      ).week;
      this.filterFiliale = JSON.parse(
        localStorage.getItem('simulation-frais-facture')
      ).filiale;
      this.filterConfirmation = JSON.parse(
        localStorage.getItem('simulation-frais-facture')
      ).confirmation;
      this.searchValue = JSON.parse(
        localStorage.getItem('simulation-frais-facture')
      ).search;
      this.filterConfirmation = JSON.parse(
        localStorage.getItem('simulation-frais-facture')
      ).confirmation;
    } else {
      this.year = courantYear;
      this.filterMonth = courantMonth;
      this.setLocalStorageSimulationFraisFacture();
    }
    for (var i = 2019; i <= courantYear; i++) {
      this.ListYear.push({ value: i, text: i });
    }
    this.fetchMonthForYearTH(this.filteryear);
    this.handleFilter();
  }
};
</script>
<style scoped lang="scss">
.similation-facture-frais {
  .entete {
    padding: 5px 10px;
  }
  .body-box-rapport {
    height: calc(100vh - 174px);
    .customTableSimulationFactureFrais {
      max-height: calc(100vh - 220px) !important;
      height: calc(100vh - 220px) !important;
      margin-bottom: 0px;
    }
  }
  .show-coef-button {
    background-color: green;
    font-size: 14px;
    padding: 3px 12px 0px 12px;
  }
  .show-montant-button {
    background-color: red;
    font-size: 14px;
    padding: 3px 12px 0px 12px;
  }
}
</style>
<style lang="scss">
.color {
  border-left: none !important;
}
.table-rapport-style .customTableSimulationFactureFrais td {
  width: calc(100vh / 4);
  line-height: 10px;
  span {
    cursor: pointer;
  }
}
.table-rapport-style .customTableSimulationFactureFrais {
  .colhead-num-dossier-th,
  .col-num-dossier-th {
    left: 30px !important;
  }
  .thvalidation,
  .thvalidation {
    width: 50px !important;
  }
  td,
  th {
    font-size: 9px;
  }
  .btn-anomalie {
    padding: 0px;
  }
}
</style>
