<template>
  <div class="confirmation-simple">
    <b-button
      v-if="data.item.confirme_frais == false"
      size="sm"
      class="iconValide activeConfirm"
      title="salaire valide"
      @click="showModal"
    >
      <font-awesome-icon icon="check" />
    </b-button>
    <b-button
      v-if="data.item.confirme_frais == true"
      size="sm"
      class="iconInvalide confirmInvalid"
      title="salaire invalide"
      @click.prevent.stop="changeConfirmationDossier()"
    >
      <font-awesome-icon icon="times" />
    </b-button>
    <!-- CONFIRMATION Modal -->
    <b-modal
      v-model="visibilityModal"
      :ref="'confirmation-dossier-facture-th-modal-unique' + id"
      no-close-on-backdrop
      :hide-footer="true"
      :title="'Confirmation des dossiers '"
      @hidden="hideModal('confirmation-dossier-facture-th-modal-unique' + id)"
      modal-class="modal-extaction-bdd-frais"
    >
      <template v-if="step == 0">
        <div>
          <div class="row">
            <div class="col-12">
              <b-form-group
                id="fieldset-headers-horizontal"
                label="Choisie les supports pour la génération automatique des factures: "
                label-for="support-selected-confirm-fact"
              >
                <b-form-checkbox
                  v-model="selectedAllSupport"
                  switch
                  @change="changeCheckSupport"
                  >Tous
                </b-form-checkbox>

                <b-form-checkbox-group
                  switches
                  stacked
                  id="checkbox-group-1"
                  v-model="selectedSupport"
                  :options="listSupport"
                  name="flavour-1"
                ></b-form-checkbox-group>
              </b-form-group>
            </div>
          </div>
        </div>

        <div class="form-modal-custom-style mt-2">
          <div class="actionModel">
            <b-button
              class="button-valide-style "
              @click.prevent.stop="changeConfirmationDossier()"
            >
              <span>
                Générer
                <div v-if="loading" class="loading ml-2">
                  <div class="spinner-border" role="status"></div>
                </div>
              </span>
            </b-button>
          </div></div
      ></template>
      <template v-if="step == 1">
        <div>
          <b-button
            size="sm"
            variant="light"
            class="button-default-style ml-2 export-facture-auto"
            title="Télecharger  cette contenue"
            @click.prevent="downloadContentAutoFacture()"
          >
            <font-awesome-icon icon="cloud-download-alt"
          /></b-button>
        </div>
        <div
          class="card-generation-fact-active"
          id="divFactureAuto"
          ref="divFactureAuto"
        >
          <div
            class="row m-1 "
            v-if="message && message.deja_exisit && message.deja_exisit.length"
          >
            <div>
              <b class="title"
                >Les supports suivant pour le dossier
                {{ message.num_dossier }}
                ont déjà des factures :
              </b>
            </div>
            <div class="ml-3 body">
              <ul v-if="Array.isArray(message.deja_exisit)">
                <li
                  v-for="(e, index) in message.deja_exisit"
                  :key="index"
                  class="li-simulation-conf"
                >
                  {{ e }}
                </li>
              </ul>
            </div>
          </div>
          <div
            class="row m-1"
            v-if="message && message.empty_value && message.empty_value.length"
          >
            <div><b class="title">Factures avec un montant zéro : </b></div>
            <div class="ml-3 body">
              <ul v-if="Array.isArray(message.empty_value)">
                <li
                  v-for="(e, index) in message.empty_value"
                  :key="index"
                  class="li-simulation-conf"
                >
                  {{ e }}
                </li>
              </ul>
            </div>
          </div>
          <div
            class="row m-1"
            v-if="message && message.generated && message.generated.length"
          >
            <div><b class="title"> Les factures générées :</b></div>
            <div class="ml-3 body">
              <ul v-if="Array.isArray(message.generated)">
                <li
                  v-for="(e, index) in message.generated"
                  :key="index"
                  class="li-simulation-conf"
                >
                  {{ e.support }} : {{ e.num }}
                </li>
              </ul>
            </div>
          </div>
          <div
            class="row m-1"
            v-if="
              message &&
                message.no_or_multiple_rib &&
                message.no_or_multiple_rib.length
            "
          >
            <div>
              <b class="title">
                Les supports suivants n'ont pas un RIB où à plusieurs RIB :</b
              >
            </div>
            <div class="ml-3 body">
              <ul v-if="Array.isArray(message.no_or_multiple_rib)">
                <li
                  v-for="(e, index) in message.no_or_multiple_rib"
                  :key="index"
                  class="li-simulation-conf"
                >
                  {{ e }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
    <!-- END CONFIRMATION Modal -->
  </div>
</template>

<script>
import html2pdf from 'html2pdf.js';
import { mapActions, mapMutations } from 'vuex';
export default {
  props: {
    data: { required: true },
    id: { required: true },
    index: { required: true }
  },
  data() {
    return {
      visibilityModal: false,
      loading: false,
      listSupport: [
        { value: 'NOVAE', text: 'NOVAE' },
        { value: 'WEB ABONDAN', text: 'PINKMAN' },
        { value: 'ISO 33', text: 'KYNANE technique' },
        { value: 'RGE', text: 'CERTILIA' },
        { value: 'DNH', text: 'GE CDG' },
        { value: 'SAV-S', text: 'SAMI-B' },
        { value: 'CORPEDYS', text: 'CORPEDYS' },
        { value: 'ACTEO', text: 'ALTEO France' },
        { value: 'alteo_control_gestion', text: 'Alteo contrôle qualité' },
        { value: 'NOVAE MAIRIE', text: 'NOVAE MAIRIE' },
        { value: 'GSE', text: 'GSE' },
        { value: 'WEB ABONDANCE', text: 'WEB ABONDANCE' },
        { value: 'upcube', text: 'UPCUBE TECHNOLOGIES' },
        { value: 'LAV', text: 'LAV' },
        { value: 'AFSP', text: 'AFSP' },
        {
          value: 'AFCONFIRMATION',
          text: 'ALTEO FRANCE Régie Commerciale INTERNE'
        },
        { value: 'AFCOMMERCIAL', text: 'ALTEO FRANCE Support Commercial' },
        // { value: 'IHS', text: 'IHS' },
        { value: 'KYNAN - ISO 33 - ISAUDIT', text: "Bureau d'etude" },
        { value: 'BE B KYNAN', text: 'BE B KYNAN' },
        { value: 'BE B ALTEO', text: 'BE B ALTEO' }
      ],
      selectedSupport: [],
      selectedAllSupport: false,
      step: 0,
      message: null,
      responseToUpdate: null
    };
  },
  methods: {
    ...mapActions(['confirmationDossierSimulation']),
    ...mapMutations(['UPDATE_SIMULATION_FACTURE_FRAIS_STAT']),
    downloadContentAutoFacture() {
      html2pdf(this.$refs.divFactureAuto, {
        margin: 1,
        filename: 'rapport-facture.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
      });
    },
    changeCheckSupport() {
      this.selectedSupport =
        this.selectedAllSupport == true
          ? this.listSupport.map(item => {
              return item.value;
            })
          : [];
    },
    async changeConfirmationDossier() {
      //   if (this.filterConfirmation == 'non') {
      //     for (let j = 0; j < this.selectedSupport.length; j++) {
      //       bodyFormData.append('supports[' + [j] + ']', this.selectedSupport[j]);
      //     }
      //   }
      let params = { data: this.data };
      if (this.data.item.confirme_frais == false) {
        params.supports = this.selectedSupport;
      }
      let showModelDetails = this.selectedSupport.length ? true : false;
      this.loading = true;
      let idModal = this.id;
      const response = await this.confirmationDossierSimulation(params);
      if (response.succes) {
        this.loading = false;
        if (showModelDetails) {
          this.message = response.data;
          this.step = 1;
          if (this.index == 1) {
            this.responseToUpdate = response.obj;
          } else {
            this.UPDATE_SIMULATION_FACTURE_FRAIS_STAT(response.obj);
          }
        } else {
          this.UPDATE_SIMULATION_FACTURE_FRAIS_STAT(response.obj);
          this.visibilityModal = false;
          let text = '<div ><div>Les  dossiers confirmés avec succès  </div>';
          this.$alert('', text, 'success');
        }
      }
    },
    showModal() {
      this.visibilityModal = true;
    },
    hideModal(ref) {
      this.resetModal();
      this.visibilityModal = false;
      if (this.responseToUpdate) {
        this.UPDATE_SIMULATION_FACTURE_FRAIS_STAT(this.responseToUpdate);
      }
      this.responseToUpdate = null;
    },
    resetModal() {
      this.loading = false;
      this.step = 0;
      this.selectedSupport = [];
      this.selectedAllSupport = false;
    }
  }
};
</script>

<style scoped lang="scss">
.button-export-style {
  background-color: green;
  font-size: 14px;
  padding: 5px 7px 5px 9px;
}
</style>
<style lang="scss">
.align-item-validate-statut-installateur-modal {
  display: flex;
}
.li-simulation-conf {
  font-size: 9px;
}
.card-generation-fact {
  border: 1px solid #e3e3e3;
  background-color: #f2f2f2;
  margin-bottom: 8px;
  border-radius: 10px;
  padding: 7px;
  .title {
    font-size: 13px;
    color: #6958bd;
  }
  .body {
    font-size: 11px;
  }
}
.card-generation-fact-active {
  background-color: #f6f5fb;
  border: 1px solid #e0ddf0;
  margin-bottom: 8px;
  border-radius: 10px;
  padding: 7px;
  .title {
    font-size: 13px;
    color: #6958bd;
  }
  .body {
    font-size: 11px;
  }
}
.export-facture-auto {
  font-size: 17px;
  left: 89%;
  position: relative;
}
</style>
