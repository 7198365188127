<template>
  <div class="column-frais-similation">
    <div
      class="default-color-statue "
      :class="{
        'black-color-statue': item[column + '_statue'] == 'facture non validée',
        'green-color': item[column + '_statue'] == 'paye',
        'red-color montant-bare': item[column + '_statue'] == 'annulee'
      }"
    >
      <EditableInputSuiviTh
        :editable="editable"
        :champName="column"
        :item="item"
        :value="item[column]"
        type="text"
        :updateFunction="updateFunction"
        :valueToShow="
          coef == true
            ? column
            : showCoef == true
            ? column
            : column + '_montant'
        "
        :showIconEdit="true"
      />
    </div>
    <!-- <div v-if="item[column + '_id']">
      <font-awesome-icon
        icon="arrow-alt-circle-down"
        @click.prevent="downloadFacture()"
        class="icon-style-color mr-1"
        title="Télécharger la facture"
      />
    </div> -->
  </div>
</template>

<script>
import EditableInputSuiviTh from '@/views/component/EditableInputSuiviTh.vue';
export default {
  props: {
    item: { required: true },
    column: { required: true },
    updateFunction: { required: true },
    coef: { default: false },
    editable: { default: true },
    showCoef: { default: false }
  },
  methods: {
    async downloadFacture() {
      await this.exportPdfFactureUploadedType({
        id: this.item[this.column + '_id']
      });
    }
  },
  components: {
    EditableInputSuiviTh
  }
};
</script>

<style lang="scss" scoped>
.column-frais-similation {
  display: flex;
}
</style>
