var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column-frais-similation"},[_c('div',{staticClass:"default-color-statue ",class:{
      'black-color-statue': _vm.item[_vm.column + '_statue'] == 'facture non validée',
      'green-color': _vm.item[_vm.column + '_statue'] == 'paye',
      'red-color montant-bare': _vm.item[_vm.column + '_statue'] == 'annulee'
    }},[_c('EditableInputSuiviTh',{attrs:{"editable":_vm.editable,"champName":_vm.column,"item":_vm.item,"value":_vm.item[_vm.column],"type":"text","updateFunction":_vm.updateFunction,"valueToShow":_vm.coef == true
          ? _vm.column
          : _vm.showCoef == true
          ? _vm.column
          : _vm.column + '_montant',"showIconEdit":true}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }