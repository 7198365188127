<template>
  <div class="custom-editable-input-th">
    <div v-if="editing" class="edit-value">
      <input
        class="input"
        :type="type"
        v-model="updateV"
        @keyup.enter="update"
        @keyup.esc="edit(false)"
        ref="autofocus"
      />
      <div class="edit-action">
        <div class="action-icon validate" @click="update">
          <font-awesome-icon icon="check" class="fa-2x bell1" />
        </div>
        <div class="action-icon cancel" @click="cancel">
          <font-awesome-icon icon="times" class="fa-2x bell1" />
        </div>
      </div>
    </div>

    <div
      v-else
      @dblclick="editable == true ? (editing = true) : null"
      class="show-value"
      :class="{ 'show-value-l': showValueL }"
    >
      {{ item[valueToShow] }} {{ uniteMontant
      }}<font-awesome-icon
        class="show-view-pencil ml-2"
        icon="pencil-alt"
        :class="{ 'show-editable-icon': showIconEdit == true }"
        v-if="editable == true"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: true
    },
    item: {
      required: false
    },
    champName: { required: true },
    type: { default: 'number' },
    updateFunction: { required: true },
    valueToShow: { required: true },
    editable: { default: true },
    showIconEdit: { default: false },
    data: { required: false },
    uniteMontant: { default: '' },
    showValueL: {
      default: false
    }
  },
  data() {
    return {
      editing: false,
      updateValue: this.value
    };
  },
  methods: {
    edit(payload) {
      this.editing = payload;
    },
    cancel() {
      this.editing = false;
      this.updateValue = this.value;
    },
    async update() {
      let value = this.updateValue;
      await this.updateFunction({
        value: value,
        row: this.item,
        column: this.champName,
        data: this.data,
        valueToShow: this.valueToShow
      });

      this.updateValue = this.value;
      this.editing = false;
    }
  },
  computed: {
    updateV: {
      get() {
        return this.value;
      },
      set(updateV) {
        this.updateValue = updateV;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-editable-input-th {
  .edit-value {
    display: flex;
    .input {
      text-align: center;
      outline: 0;
      border: 1px solid #ded3d5;
      font-weight: 400;
      font-size: 12px;
      min-width: 55px;
      background-color: transparent;
      width: 100%;
      border-radius: 5px;
    }
    .edit-action {
      .action-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 19px;
        margin: 0 1px;
        margin-left: 1px;
        margin-right: 1px;
        border: 1px solid #ded3d5;
        font-size: 6px;
        cursor: pointer;

        &.validate {
          color: #4d4bac;
          width: 20px;
          height: 20px;
          margin-bottom: 2px;
          border-radius: 5px;
          &:hover {
            color: #ffffff;
            background-color: #4d4bac;
          }
        }

        &.cancel {
          color: #dc3c3c;
          border-radius: 5px;
          &:hover {
            color: #ffffff;
            background-color: #dc3c3c;
          }
        }
      }
    }
  }
  .show-value {
    min-width: 10px;
    min-height: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .show-view-pencil {
      color: #050505;
      opacity: 0;
      display: none;
      transition: opacity 0.2s ease;
    }
    .show-editable-icon {
      display: inline-block;
      opacity: 1;
    }
    &:hover {
      .show-view-pencil {
        display: inline-block;
        opacity: 1;
      }
    }
  }
  .show-value-l {
    min-width: 82px;
  }
}
</style>
